import "mapbox-gl/dist/mapbox-gl.css";
import React, { useCallback, useState } from "react";
import MapGL, { Marker } from "react-map-gl";
import { PortableTextBlock } from "../../sanity/portableTextBlock";
import { BlockWrapper } from "../blockWrapper";
import { ExternalLink } from "../../ui";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation } from "@reach/router";
import { motion } from "framer-motion";
import cx from "classnames";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Map = () => {
  const lat = -36.84486466940737;
  const long = 174.77044683325488;
  const [viewport, setViewport] = useState({
    latitude: lat,
    longitude: long,
    zoom: 16,
  });

  const handleViewportChange = useCallback((newViewport) => {
    setViewport(newViewport);
  }, []);

  const settings = {
    scrollZoom: false,
  };

  return (
    <div className="col-start-2 col-end-14 mt-12 relative h-96 lg:h-[40rem] mb-6 lg:my-24 lg:px-24">
      <MapGL
        {...settings}
        {...viewport}
        zoom={16.25}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/bravemedia/clh6rigze009t01r638kl93nt"
        mapboxAccessToken="pk.eyJ1IjoiYnJhdmVtZWRpYSIsImEiOiJjbGg2cjE2c2swOGl1M3BtcmMwdDNmaW83In0.17tbupT7cRJXQky17hOJiA"
        onViewportChange={handleViewportChange}
        style={{ borderRadius: "1.25rem" }}
      >
        <Marker
          latitude={lat}
          longitude={long}
          color="#31677B"
          className="w-8 h-8 lg:w-16 lg:h-16"
        />
      </MapGL>
    </div>
  );
};

const ContactForm = ({
  blockConfig,
  _rawAddress,
  emailplaceholder: emailPlaceHolder,
  nameplaceholder: namePlaceHolder,
  messageplaceholder: messagePlaceholder,
  numberplaceholder: numberPlaceholder,
  submittext: submitText,
}) => {
  const { pathname } = useLocation();

  const formCommonClassName =
    "py-2 pb-3 border-b border-dark-navy placeholder:text-dark-navy";
  const formCommonStyle = { backgroundColor: "transparent" };

  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div className="col-start-2 col-end-14 lg:col-end-5 row-start-1 row-end-2 mt-12 lg:mt-0 text-center lg:text-left">
        <PortableTextBlock
          text={_rawAddress}
          className="prose prose-contact mx-auto"
        />
      </div>
      <Formik
        initialValues={{
          email: "",
        }}
        validate={(values) => {
          const errors = {};

          if (!values.email) {
            errors.email = "Email address is Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          fetch(pathname || "/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
              "form-name": `contact-form`,
              ...values,
            }),
          })
            .then(() => {
              setSubmitting(false);
              setStatus("success");
            })
            .catch((error) => alert(error));
        }}
      >
        {({
          onChange,
          isSubmitting,
          status,
          values,
          /* and other goodies */
        }) => (
          <div
            className={cx(
              "col-start-2 lg:col-start-7 col-end-14 rounded-xl",
              status === "success"
                ? "flex justify-center items-center bg-none"
                : "mt-12 lg:mt-0 bg-slate/5"
            )}
          >
            <Form
              name="contact-form"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              subject="Website Enquiry"
              className="flex flex-col p-6 lg:p-12 gap-y-6 text-dark-navy"
            >
              {/* Hidden Fields for netlify */}
              <input type="hidden" name="form-name" value="contact-form" />
              <p hidden>
                <label>
                  <input name="bot-field" onChange={onChange} />
                </label>
              </p>
              {status === "success" ? (
                <div className="col-span-full text-center my-10">
                  <motion.h2
                    className="flex font-bold text-teal focus:outline blockH5"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                  >
                    Thanks for getting in touch with us at Castle Point Funds.
                    We will contact you as soon as possible.
                  </motion.h2>
                </div>
              ) : (
                <>
                  {/* Form Fields */}
                  <Field
                    required
                    type="text"
                    name="name"
                    className={formCommonClassName}
                    style={formCommonStyle}
                    placeholder={namePlaceHolder}
                  />
                  <Field
                    required
                    type="number"
                    name="number"
                    className={formCommonClassName}
                    style={formCommonStyle}
                    placeholder={numberPlaceholder}
                  />
                  <Field
                    required
                    type="email"
                    name="email"
                    className={formCommonClassName}
                    style={formCommonStyle}
                    placeholder={emailPlaceHolder}
                  />
                  <Field
                    required
                    type="textarea"
                    name="message"
                    rows="6"
                    as="textarea"
                    className={formCommonClassName}
                    style={formCommonStyle}
                    placeholder={messagePlaceholder}
                  />

                  {/* Submit */}
                  <button
                    className="flex ml-auto font-bold text-teal focus:outline blockH6 hover:opacity-75"
                    type="submit"
                  >
                    {submitText}
                  </button>
                </>
              )}
            </Form>
          </div>
        )}
      </Formik>

      {/* Map */}
      <Map />

      {/* Complaints & External Dispute Resolution */}
      <div className="grid lg:grid-cols-2 gap-12 lg:gap-24 col-start-2 col-end-14 mt-12 ">
        <div>
          <h2 className="blockH4 text-teal">Complaints</h2>
          <p className="my-4">
            If something has gone wrong, we would like to work with you to
            resolve it. Please email us at{" "}
            <a
              className="text-teal font-bold"
              href="mailto:info@castlepointfunds.com"
            >
              info@castlepointfunds.com
            </a>{" "}
            if you have a complaint, along with any documents or correspondence
            that will help us understand what's happened.
          </p>
          <p className="my-4">When we receive your complaint, we will:</p>
          <ul className="list-item list-disc ml-6 mb-4">
            <li>Acknowledge your complaint within 1-2 working days</li>
            <li>Gather and evaluate information about your complaint</li>
            <li>Respond to you within 20 working days</li>
          </ul>
        </div>
        <div>
          <h2 className="blockH4 text-teal"> External Dispute Resolution</h2>
          <p className="my-4">
            If you are not happy with our response, you can contact Financial
            Services Complaints Limited (FSCL) for independent assistance. FSCL
            are an independent, not-for-profit, dispute resolution scheme
            approved by the Minister of Consumer Affairs. FSCL's service is
            free, and they will help resolve the complaint.
          </p>
          <p className="my-4">
            Please note that before the FSCL can review your complaint, it must
            have gone through the full complaint process and we have given our
            response.
          </p>
          <p>FSCL's contact details:</p>
          <p>
            <span className="font-bold">Phone:</span>{" "}
            <a className="text-teal font-bold" href="tel:0800347257">
              0800 347 257
            </a>
          </p>
          <p>
            <span className="font-bold">Email:</span>{" "}
            <a
              className="text-teal font-bold"
              href="mailto:complaints@fscl.org.nz"
            >
              complaints@fscl.org.nz
            </a>
          </p>
          <p>
            <span className="font-bold">Website:</span>{" "}
            <ExternalLink
              className="text-teal font-bold"
              link="http://www.fscl.org.nz"
              linkText="www.fscl.org.nz"
            />
          </p>
          <p>
            <span className="font-bold">Mailing address:</span> PO Box 5967,
            WELLINGTON 6140
          </p>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default ContactForm;
